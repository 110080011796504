import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  NativeSelect,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import Axios from "axios";
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import { ConvertStr2DatetimeLocal } from "../../common/timeParsing.js";

const productStatusOnline = 2;
const orderSourceTypeBackend = 2;
const orderChargeTypeYear = 1;
const orderChargeTypeMonth = 2;
const orderChargeTypeTrialProduct = 4;
const planTypeDsis = "企業上網月繳方案";

// 產品廠牌
// const BrandInit = 0;
// const BrandMicrosoftSn = 1;
// const BrandGoogleSn = 2;

// Time Settings
const productDetailPlanIsNotFlexible = false;
const productDetailPlanIsFlexible = true;

// GWS kind parameter
const countryCodeOfTaiwan = "TW";
const customerDomainVerifiedDefaultValue = false;
const customerTypeDefaultValue = "domain";

const mdSize = 6;
const xsSize = 12;

// const planTypeList = [
//     {
//         Sn: 11,
//         Name: "年約月繳方案",
//         PlanName: "ANNUAL_MONTHLY_PAY"
//     }, {
//         Sn: 12,
//         Name: "年約年繳方案",
//         PlanName: "ANNUAL_YEARLY_PAY"
//     }, {
//         Sn: 13,
//         Name: "彈性方案",
//         PlanName: "FLEXIBLE"
//     }
// ];

// const productList = [
//     {
//         "Sn": 1,
//         "Name": "Google Workspace Business Starter",
//         "gOfferId": "1010020027",
//         "gOfferName": "Google Workspace Business Starter"
//     }, {
//         "Sn": 2,
//         "Name": "Google Workspace Business Standard",
//         "gOfferId": "1010020028",
//         "gOfferName": "Google Workspace Business Standard"
//     }, {
//         "Sn": 3,
//         "Name": "Google Workspace Business Plus",
//         "gOfferId": "1010020025",
//         "gOfferName": "Google Workspace Business Plus"
//     }, {
//         "Sn": 4,
//         "Name": "Google Workspace Enterprise Essentials",
//         "gOfferId": "1010060003",
//         "gOfferName": "Google Workspace Enterprise Essentials"
//     }, {
//         "Sn": 5,
//         "Name": "Google Workspace Enterprise Standard",
//         "gOfferId": "1010020026",
//         "gOfferName": "Google Workspace Enterprise Standard"
//     }, {
//         "Sn": 6,
//         "Name": "Google Workspace Enterprise Plus",
//         "gOfferId": "1010020020",
//         "gOfferName": "Google Workspace Enterprise Plus"
//     }, {
//         "Sn": 7,
//         "Name": "Google Workspace Essentials",
//         "gOfferId": "1010060001",
//         "gOfferName": "Google Workspace Essentials"
//     }, {
//         "Sn": 8,
//         "Name": "Google Workspace Frontline",
//         "gOfferId": "1010020030",
//         "gOfferName": "Google Workspace Frontline"
//     }
// ];

const renewalTypeListDefault = [
  {
    sn: 1,
    name: "自動續約年約年繳",
    value: "AUTO_RENEW_YEARLY_PAY",
  },
  {
    sn: 2,
    name: "自動續約年約月繳",
    value: "AUTO_RENEW_MONTHLY_PAY",
  },
  {
    sn: 3,
    name: "以目前帳號內使用者數量續約年約年繳",
    value: "RENEW_CURRENT_USERS_YEARLY_PAY",
  },
  {
    sn: 4,
    name: "以目前帳號內使用者數量續約年約月繳",
    value: "RENEW_CURRENT_USERS_MONTHLY_PAY",
  },
  {
    sn: 5,
    name: "改以彈性合約續約",
    value: "SWITCH_TO_PAY_AS_YOU_GO",
  },
  {
    sn: 6,
    name: "取消訂閱",
    value: "CANCEL",
  },
];

const memberDefault = {
  aaHnNo: "",
  name: "",
  companyNo: "",
  address: "",
  zip: "",
  telMorning: "",
  email: "",
  contactName: "",
  cell: "",
};

const orderDefault = {
  postalAddressSn: "",
  orderDate: "",
  orderStartDate: "",
  orderDisSubscribeDate: productDetailPlanIsNotFlexible,
  domain: "",
  promoEmpno: "",
  productDetailSn: "",
  operatorNote: "",
  quantity: "",
  orderSourceTypeSn: orderSourceTypeBackend,
  orderChargeTypeSn: 0,
  googleCustomerDomainVerified: customerDomainVerifiedDefaultValue,
  customerType: customerTypeDefaultValue,
  googleAlternateEmail: "",
  googleResourceUiUrl: "",
  googleRenewalTypeSn: "",
  googleMaximumSeats: "",
  isGoogleExistingOrder: false,
  id: "",
  googleSubscriptionId: "",
};

const googlePostalAddressDefault = {
  countryCode: countryCodeOfTaiwan,
  region: "",
  locality: "",
  contactName: "",
  organizationName: "",
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  postalCode: "",
};

// const existingOrderProperty = {
//   subscriptionId: "",
//   customerId: ""
// };

// include country-code json
const countryCodeList = require("../countryCode.json");

// API
const getMemberURL = "/api/order/getSingleHnDataList";
const getProductDetailListURL = "/api/product/detail/list";
const getRenewalTypeListURL = "/api/order/google/getRenewalType";
const saveNewOrderURL = "/api/order/createNewOrder";
const saveNewGooglePostalAddressURL =
  "/api/order/google/createGooglePostalAddress";

export default function PurchaseModelOfGoogle(props) {
  const [modalStatus, setModalStatus] = useState(false);
  const [member, setMember] = useState(memberDefault);
  const [order, setOrder] = useState(orderDefault);
  const [googlePostalAddress, setGooglePostalAddress] = useState(
    googlePostalAddressDefault
  );
  // const [existingOrderProp, setExistingOrderProp] = useState(existingOrderProperty);

  // 是否為舊有訂單
  const [isExist, setIsExist] = useState(false);

  // 是否為測試版
  // const [isTrialPlan, setIsTrialPlan] = useState(false);

  // 彈性方案不需要訂單到期續約方案
  const [enableRenewal, setEnableREnewal] = useState(true);

  // Domain 可使用
  // const [isDomainAvailable, setIsDomainAvailable] = useState(false);

  const [productDetailList, setProductDetailList] = useState([]);
  const [renewalTypeList, setRenewalTypeList] = useState([]);
  const [keepModal, setKeepModal] = useState(false);
  const [hnError, setHnError] = useState(false);
  const [domainError, setDomainError] = useState(false);
  const [alternateEmailError, setAlternateEmailError] = useState(false);
  const [enterErrorMessageOfHN, setEnterErrorMessageOfHN] = useState(
    "請輸入 HN（八碼數字）。若有資料，系統會自動填入相關資訊"
  );
  const [enterErrorMessageOfDomain, setEnterErrorMessageOfDomain] =
    useState("輸入後，系統會自動驗證 Domain");
  const [
    enterErrorMessageOfAlternateEmail,
    setEnterErrorMessageOfAlternateEmail,
  ] = useState("輸入後，系統會自動檢查 Email 格式");

  const closeModal = props.closeModal;
  const openDetailModal = props.openDetailModal;

  const getProductDetailList = useCallback(() => {
    var postData = {
      orderBy: "product",
      brandSn: props.brandSn,
    };
    Axios.post(getProductDetailListURL, postData).then((response) => {
      console.table(response.data.productDetail);
      setProductDetailList(response.data.productDetail);
    });
  }, [props.brandSn]);

  const getRenewalTypeList = useCallback(() => {
    Axios.get(getRenewalTypeListURL)
      .then((response) => {
        console.table(response.data);
        // setRenewalTypeList(response.data);
        setRenewalTypeList(response.data);
      })
      .catch((err) => {
        console.log("Function getRenewalTypeList() : " + err);
        setRenewalTypeList(renewalTypeListDefault);
      });
  }, []);

  useEffect(() => {
    if (props.open) {
      getProductDetailList();
      getRenewalTypeList();
    }
    setModalStatus(props.open);
  }, [props.open, getProductDetailList, getRenewalTypeList]);

  const getMemberData = (aaHnNo = 0) => {
    if (
      aaHnNo !== 0 &&
      aaHnNo.toString() !== "" &&
      aaHnNo.toString().length >= 8
    ) {
      Axios.post(getMemberURL, { Hn: aaHnNo })
        .then((response) => {
          setEnterErrorMessageOfHN("系統已填入資料。");
          setHnError(false);
          setMember(response.data.dataList);
        })
        .catch((error) => {
          setEnterErrorMessageOfHN("找無此人或無資料。");
          setHnError(true);
          setMember(memberDefault);
          console.error(error);
        });
    } else {
      setEnterErrorMessageOfHN(
        "請輸入 HN（八碼數字）。若有資料，系統會自動填入相關資訊"
      );
      setMember(memberDefault);
      setOrder(orderDefault);
    }
  };
  useEffect(() => {
    // member Info 可以先填入 Order form (GooglePostalAddress Obj)
    // console.table(member);
    setGooglePostalAddress((prevState) => ({
      ...prevState,
      contactName: member.contactName !== "" ? member.contactName : "",
      organizationName: member.name !== "" ? member.name : "",
      postalCode: member.zip !== "" ? member.zip : "",
      addressLine1: member.address !== "" ? member.address : "",
    }));

    // 寫入備用信箱
    handleChangeOrder({
      target: {
        type: "text",
        name: "googleAlternateEmail",
        value: member.email ?? "",
      },
    });
  }, [member]);

  const handleChangeMember = (event) => {
    var { name, value } = event.target;
    const regexNum = /^[0-9\b]+$/;

    if (value === "" || regexNum.test(value)) {
      setMember((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleChangeOrder = (event) => {
    var { name, value, type } = event.target;

    // check domain
    const regexDomain = /^[A-Za-z0-9_.\-\b]+$/;
    if (name === "domain") {
      if (!regexDomain.test(value) && value !== "") {
        return;
      }
    }

    if (type === "number") {
      value = parseInt(value);
      value = value > 2147483647 ? 2147483647 : value;
      value = value < 1 ? 1 : value;
      if (name === "googleMaximumSeats") {
        // order.googleMaximumSeats 與 order.quantity 同步，這樣index table 才會顯示帳號數量(quantity)。
        setOrder((prevState) => ({
          ...prevState,
          quantity: value,
        }));
      }
    } else if (type === "select-one") {
      if (name === "productDetailSn") {
        value = parseInt(value);
        value = value > 2147483647 ? 2147483647 : value;
        value = value < 1 ? 1 : value;

        //原先的 productdetail 中的彈性方案和最大訂閱數的互動。

        for (let i = 0; i < productDetailList.length; i++) {
          if (productDetailList[i].sn === value) {
            // 找到對應的 productDetail 資料
            if (productDetailList[i].plan === "彈性方案") {
              // If flexible plan, renewal is unneeded
              setEnableREnewal(false);
              // setIsTrialPlan(false);
              console.log("彈性方案，所以不先設定退訂日期。");
              setOrder((prevState) => ({
                ...prevState,
                googleRenewalTypeSn: renewalTypeListDefault[4].sn,
                orderDisSubscribeDate: productDetailPlanIsNotFlexible,
                orderChargeTypeSn: 0,
              }));
            } else if (productDetailList[i].plan === "試用版") {
              setEnableREnewal(true);
              setOrder((prevState) => ({
                ...prevState,
                googleRenewalTypeSn: "",
                orderDisSubscribeDate: productDetailPlanIsFlexible,
                orderChargeTypeSn: orderChargeTypeTrialProduct,
              }));
            } else {
              // 清空 最大訂閱量欄位的值。
              setEnableREnewal(true);
              console.log("年約方案，所以會先設定退訂日期。");
              setOrder((prevState) => ({
                ...prevState,
                googleRenewalTypeSn: "",
                orderDisSubscribeDate: productDetailPlanIsFlexible,
                // 年約者強制直接年繳收取, 以免客戶中途退租, 本公司需吸收客戶剩餘年約之費用
                orderChargeTypeSn: (productDetailList[i].plan === "年約月繳方案") ? orderChargeTypeMonth : orderChargeTypeYear,
              }));
            }
            break;
          } else {
            // 尚未選擇，option 為第0筆資料的狀況，關閉最大訂閱量欄位的值。
            console.log("尚未選擇，所以不先設定退訂日期。");
            setOrder((prevState) => ({
              ...prevState,
              orderDisSubscribeDate: productDetailPlanIsNotFlexible,
            }));
          }
        }
      } else if (name === "googleRenewalTypeSn") {
        value = parseInt(value);
        value = value > 2147483647 ? 2147483647 : value;
        value = value < 1 ? 1 : value;
      }
    } else if (type === "text") {
      if (name === "googleAlternateEmail") {
        // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // if ( re.test(value) ) {
        // const inputEmail = value + "@" + order.domain;
        if (isEmail(value)) {
          // if (isEmail(inputEmail)) {
          setEnterErrorMessageOfAlternateEmail("此 Email 格式正確。");
          setAlternateEmailError(false);
          // this is a valid email address
          // call setState({email: email}) to update the email
          // or update the data in redux store.
        } else {
          setEnterErrorMessageOfAlternateEmail("此 Email 格式錯誤。");
          setAlternateEmailError(true);
          // invalid email, maybe show an error to the user.
        }
      }
    }

    // console.log("name:" + name);
    // console.log("type:" + type);
    // console.log("value:" + value);

    setOrder((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGooglePostalAddress = (event) => {
    var { name, value } = event.target;

    setGooglePostalAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeExistingOrder = (event) => {
    var { name, value } = event.target;

    if (name === "orderStartDate") {
      // convert local datetime to string with timezone
      value = new Date(value).toISOString();
    }

    setOrder((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // 有 Google domain api 驗證。
  const gwsCheckDomainByDomain = () => {
    // If this order hasn't been created
    if (!isExist && order.domain !== "") {
      Axios.get(`/api/v0/gws/customers/${order.domain}`)
        .then((response) => {
          console.log(response);
          setEnterErrorMessageOfDomain("Domain 已被使用。");
          setDomainError(true);
          // setIsDomainAvailable(false);
        })
        .catch((error) => {
          console.table(error);
          if (error.response === undefined) {
            setEnterErrorMessageOfDomain("未取得 token 或其他問題。");
            //setDomainError(true);
            setDomainError(false);
            // setIsDomainAvailable(false);
          } else if (error.response.status === 404) {
            setEnterErrorMessageOfDomain("Domain 可使用。");
            setDomainError(false);
            // setIsDomainAvailable(true);
          } else {
            setEnterErrorMessageOfDomain("後端發生未知問題。");
            setDomainError(true);
            // setIsDomainAvailable(false);
          }
        });
    }
  };

  const setKeepModalStatus = (isKeep) => {
    setKeepModal(isKeep);
  };

  // Security Design
  const convertDataToHiddenCode = (val) => {
    let strLength = val.length;
    let data = "";
    if (strLength !== 0 && strLength !== undefined && strLength !== null) {
      for (let i = 0; i < strLength; i++) {
        data += "*";
      }
    }
    return data;
  };

  // 元件改為 Autocomplete 暫時用 renderProductDetailOptionForAutocomplete 取代
  // const renderProductDetailOption = (
  //   productDetailList,
  //   isTrialPlanRenewal = false
  // ) => {
  //   if (productDetailList === null) {
  //     return <option value="">沒有任何訂閱商品可選擇</option>;
  //   } else {
  //     return productDetailList.map((product, index) => {
  //       return isTrialPlanRenewal && product.plan === "試用版" ? (
  //         <></>
  //       ) : (
  //         <option
  //           key={index}
  //           value={product.sn}
  //           disabled={
  //             !isExist &&
  //             !(
  //               product.product_reviewed &&
  //               product.product_status === productStatusOnline &&
  //               product.product_gOfferId !== "" &&
  //               !product.plan.includes(planTypeDsis)
  //             )
  //           }
  //         >
  //           {product.product}
  //           {product.plan !== "" ? " - " + product.plan : ""}
  //           {product.discode !== "" ? " - " + product.discode : ""}
  //           {product.price_special !== ""
  //             ? ", 售價: " + product.price_special + "元"
  //             : ""}
  //         </option>
  //       );
  //     });
  //   }
  // };

  const renderProductDetailOptionForAutocomplete = (option) => {
    let optionString = "";

    optionString = optionString + option.product;
    optionString =
      optionString + (option.plan !== "" ? " - " + option.plan : "");
    optionString =
      optionString + (option.discode !== "" ? " - " + option.discode : "");
    optionString =
      optionString +
      (option.price_special !== ""
        ? ", 售價: " + option.price_special + "元"
        : "");

    return optionString;
  };

  const setIsExistingOrder = () => {
    setIsExist(!isExist);
    setOrder((prevState) => ({
      ...prevState,
      isGoogleExistingOrder: !isExist,
    }));
  };

  var orderDataList = [
    createLabel(
      "切換新增訂單模式:",
      !isExist ? (
        <Button
          style={{
            backgroundColor: "#21b6ae",
          }}
          onClick={setIsExistingOrder}
        >
          轉換成匯入舊有訂單
        </Button>
      ) : (
        <Button
          style={{
            backgroundColor: "#21b6ae",
          }}
          onClick={setIsExistingOrder}
        >
          轉換成新建訂單
        </Button>
      )
    ),
    // Padding
    createLabel("", <></>),
    createLabel(
      "HN 帳號:",
      <TextField
        fullWidth
        name={"aaHnNo"}
        value={member.aaHnNo}
        error={hnError}
        onChange={handleChangeMember}
        disabled={keepModal}
        placeholder="（必填欄位 / Required Field）"
        autoComplete="off"
        required
        helperText={enterErrorMessageOfHN}
        onBlur={() => getMemberData(member.aaHnNo)}
      />
    ),
    createLabel(
      "訂閱商品：",
      <Autocomplete
        noOptionsText={"（沒有任何訂閱商品可選擇）"}
        autoComplete={true}
        disableClearable={true}
        disablePortal={true}
        disabled={keepModal}
        onChange={(event, value) =>
          // handleChangeOrderForAutocomplete("productDetailSn", value)
          handleChangeOrder({
            target: {
              name: "productDetailSn",
              type: "select-one",
              value: value.sn,
            },
          })
        }
        options={productDetailList}
        getOptionDisabled={(option) =>
          !isExist &&
          !(
            option.product_reviewed &&
            option.product_status === productStatusOnline &&
            option.product_gOfferId !== "" &&
            !option.plan.includes(planTypeDsis)
          )
        }
        getOptionLabel={(option) =>
          renderProductDetailOptionForAutocomplete(option)
        }
        renderInput={(params) => (
          <TextField {...params} placeholder="（支援文字輸入搜尋）" fullWidth />
        )}
      ></Autocomplete>
    ),
    createLabel(
      "使用網域：",
      <TextField
        fullWidth="fullWidth"
        name={"domain"}
        value={order.domain}
        error={domainError}
        autoComplete="off"
        inputProps={{ maxLength: 100 }}
        onChange={handleChangeOrder}
        placeholder="（必填欄位 / Required Field）"
        required="required"
        disabled={keepModal}
        // helperText={<p>請客戶先自行確認該「網域使用權」，無誤後再填入。</p>}
        helperText={enterErrorMessageOfDomain}
        onBlur={() => gwsCheckDomainByDomain()}
      />
    ),
    // createLabel(
    //   "主要信箱：",
    //   <TextField
    //     fullWidth
    //     name={"primaryEmail"}
    //     value={order.primaryEmail}
    //     placeholder="（必填欄位 / Required Field）"
    //     onChange={handleChangeOrder}
    //     disabled={keepModal}
    //     required
    //   />
    // ),
    // (isDomainAvailable || isExist) ? (
    //   createLabel(
    //     "備用信箱：",
    //     (isExist) ? (
    //       <TextField
    //         fullWidth
    //         name={"googleAlternateEmail"}
    //         value={order.googleAlternateEmail}
    //         placeholder="（必填欄位 / Required Field）"
    //         required
    //         error={alternateEmailError}
    //         autoComplete='off'
    //         onChange={handleChangeOrder}
    //         disabled={keepModal}
    //         helperText={enterErrorMessageOfAlternateEmail}
    //       />
    //     ) : (
    //       <Grid container spacing={2}>
    //         <Grid item xs={6}>
    //           <TextField
    //             fullWidth
    //             name={"googleAlternateEmail"}
    //             value={order.googleAlternateEmail}
    //             placeholder="（必填欄位）"
    //             required
    //             error={alternateEmailError}
    //             autoComplete='off'
    //             onChange={handleChangeOrder}
    //             disabled={keepModal}
    //             helperText={enterErrorMessageOfAlternateEmail}
    //           />
    //         </Grid>
    //         <Grid item xs={6}>
    //           <TextField
    //             fullWidth
    //             name={"googleAlternateEmail"}
    //             value={"@" + order.domain}
    //             disabled={true}
    //           />
    //         </Grid>
    //       </Grid>
    //     )
    //   )) : (<></>),
    createLabel(
      "備用信箱：",
      <TextField
        fullWidth
        name={"googleAlternateEmail"}
        value={order.googleAlternateEmail}
        placeholder="（必填欄位 / Required Field）"
        required
        error={alternateEmailError}
        autoComplete="off"
        onChange={handleChangeOrder}
        disabled={keepModal}
        helperText={enterErrorMessageOfAlternateEmail}
      />
    ),
    createLabel(
      "聯絡人姓名：",
      <TextField
        fullWidth
        name={"contactName"}
        value={googlePostalAddress.contactName}
        placeholder="（必填欄位 / Required Field）"
        onChange={handleGooglePostalAddress}
        autoComplete="off"
        disabled={keepModal}
        required
      />
    ),
    createLabel(
      "組織或企業名稱：",
      <TextField
        fullWidth
        name={"organizationName"}
        value={googlePostalAddress.organizationName}
        // value={order.organizationName || organizationName}
        placeholder="（必填欄位 / Required Field）"
        onChange={handleGooglePostalAddress}
        autoComplete="off"
        // onBlur={handleGooglePostalAddress}
        disabled={keepModal}
        required
      />
    ),
    createLabel(
      "國家區號：",
      <FormControl fullWidth>
        <NativeSelect
          name="countryCode"
          value={googlePostalAddress.countryCode}
          onChange={handleGooglePostalAddress}
          autoComplete="off"
        >
          {countryCodeList.map((countryInfo, index) => {
            if (countryInfo.CountryName === null) {
              return false;
            } else {
              return (
                <option key={index} value={countryInfo.ISO3166}>
                  {`${countryInfo.CountryName}\t( ${countryInfo.ISO3166} )\t`}
                </option>
              );
            }
          })}
        </NativeSelect>
      </FormControl>
    ),
    createLabel(
      "郵遞區號：",
      <TextField
        fullWidth
        name={"postalCode"}
        value={googlePostalAddress.postalCode}
        placeholder="（必填欄位 / Required Field）"
        onChange={handleGooglePostalAddress}
        disabled={keepModal}
        autoComplete="off"
        required
      />
    ),
    createLabel(
      "城市 / 州名：",
      <TextField
        fullWidth
        name={"region"}
        value={googlePostalAddress.region}
        placeholder="（選填欄位 / Optional Field）"
        onChange={handleGooglePostalAddress}
        disabled={keepModal}
        autoComplete="off"
      />
    ),
    createLabel(
      "行政區 / 區域：",
      <TextField
        fullWidth
        name={"locality"}
        value={googlePostalAddress.locality}
        placeholder="（選填欄位 / Optional Field）"
        onChange={handleGooglePostalAddress}
        disabled={keepModal}
        autoComplete="off"
      />
    ),
    createLabel(
      "地址 1：",
      <TextField
        fullWidth
        name={"addressLine1"}
        value={googlePostalAddress.addressLine1}
        placeholder="（必填欄位 / Required Field）"
        onChange={handleGooglePostalAddress}
        disabled={keepModal}
        autoComplete="off"
        required
      />
    ),
    createLabel(
      "地址 2：",
      <TextField
        fullWidth
        name={"addressLine2"}
        value={googlePostalAddress.addressLine2}
        placeholder="（選填欄位 / Optional Field）"
        onChange={handleGooglePostalAddress}
        disabled={keepModal}
        autoComplete="off"
      />
    ),
    createLabel(
      "地址 3：",
      <TextField
        fullWidth
        name={"addressLine3"}
        value={googlePostalAddress.addressLine3}
        placeholder="（選填欄位 / Optional Field）"
        onChange={handleGooglePostalAddress}
        disabled={keepModal}
        autoComplete="off"
      />
    ),
    // createLabel(
    //   "SKU ID：",
    //   <TextField
    //     fullWidth
    //     name={"skuId"}
    //     value={order.skuId}
    //     placeholder="（必填欄位 / Required Field）"
    //     onChange={handleChangeOrder}
    //     disabled={keepModal}
    //     required
    //   />
    // ),
    // createLabel(
    //   "繳費方案：",
    //   <FormControl fullWidth>
    //     <NativeSelect
    //       name="planType"
    //       value={order.planType}
    //       onChange={handleChangeOrder}
    //     >
    //       {planTypeList.map((planInfo, index) => {
    //         return (
    //           <option key={index} value={planInfo.PlanName}>
    //             {planInfo.Name}
    //           </option>
    //         );
    //       })}
    //     </NativeSelect>
    //   </FormControl>
    // ),
    createLabel(
      "購買訂單ID：",
      <TextField
        fullWidth
        name={"purchaseOrderId"}
        value={order.purchaseOrderId}
        placeholder="（選填欄位 / Optional Field）"
        onChange={handleChangeOrder}
        autoComplete="off"
      />
    ),
    createLabel(
      "年度續訂設置：",
      <FormControl fullWidth>
        <NativeSelect
          name="googleRenewalTypeSn"
          value={order.googleRenewalTypeSn}
          onChange={handleChangeOrder}
          disabled={keepModal || !enableRenewal}
        >
          <option value=""></option>
          {renewalTypeList.map((renewalInfo, index) => {
            return (
              <option key={index} value={renewalInfo.sn}>
                {renewalInfo.name}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>
    ),
    createLabel(
      "最大訂閱量：",
      <TextField
        fullWidth
        name={"googleMaximumSeats"}
        type="number"
        value={order.googleMaximumSeats}
        placeholder="（必填欄位 / Required Field）"
        onChange={handleChangeOrder}
        disabled={keepModal}
        autoComplete="off"
        // disabled={keepModal || !productIsFlexible}
        required
      />
    ),
    // createLabel(
    //   "所需訂閱量：",
    //   <TextField
    //     fullWidth
    //     name={"quantity"}
    //     type="number"
    //     value={order.quantity}
    //     placeholder="（必填欄位 / Required Field）"
    //     onChange={handleChangeOrder}
    //     disabled={keepModal}
    //     required
    //   />
    // ),
    // createLabel(
    //   "資源操作頁面 URL：",
    //   <TextField
    //     fullWidth
    //     name={"googleResourceUiUrl"}
    //     value={order.googleResourceUiUrl}
    //     placeholder="（無須填寫）"
    //     onChange={handleChangeOrder}
    //     disabled
    //   />
    // )
  ];

  var importExistingOrderFields = [
    createLabel(
      "Google 客戶 ID",
      <TextField
        fullWidth
        name={"id"}
        value={order.id}
        placeholder="（必填欄位 / Required Field）"
        onChange={handleChangeExistingOrder}
        autoComplete="off"
      />
    ),
    createLabel(
      "Google 訂閱 ID",
      <TextField
        fullWidth
        name={"googleSubscriptionId"}
        value={order.googleSubscriptionId}
        placeholder="（必填欄位 / Required Field）"
        onChange={handleChangeExistingOrder}
        autoComplete="off"
      />
    ),
    createLabel(
      "啟用日期(收費)：",
      <TextField
        fullWidth
        type="datetime-local"
        name={"orderStartDate"}
        helperText={"請設定開通日期, 否則無法出帳"}
        value={ConvertStr2DatetimeLocal(order.orderStartDate)}
        disabled={keepModal}
        onChange={handleChangeExistingOrder}
        inputProps={{ step: 1 }} // for datetime to show second
      />
    ),
  ];

  // var trialOrderOperation = [
  //   createLabel("接續付費方案",
  //     <FormControl fullWidth>
  //       <NativeSelect
  //         id="demo-customized-select-native"
  //         value={order.productDetailSn}
  //         type="number"
  //         name={"productDetailSn"}
  //         onChange={handleChangeOrder}
  //         disabled={keepModal}
  //         required
  //       >
  //         <option value=""></option>
  //         {renderProductDetailOption(productDetailList, true)}
  //       </NativeSelect>
  //     </FormControl>
  //   ),
  //   // createLabel("開通付費方案",
  //   //   <Button
  //   //     style={{
  //   //       backgroundColor: "#21b6ae"
  //   //     }}
  //   //     onClick={handleActivatePaidService}
  //   //   >
  //   //     直接開通
  //   //   </Button>
  //   // )
  // ];

  var memberDataList = [
    createLabel("公司/客戶名稱:", member.name),
    createLabel("統編/證號:", convertDataToHiddenCode(member.companyNo)),
    createLabel("帳單地址:", convertDataToHiddenCode(member.address)),
    createLabel("郵遞區號:", convertDataToHiddenCode(member.zip)),
    createLabel("連絡電話:", convertDataToHiddenCode(member.telMorning)),
    createLabel("聯絡電話(手機):", convertDataToHiddenCode(member.cell)),
    createLabel("聯絡email:", member.email),
    createLabel("聯絡人姓名:", member.contactName),
  ];

  function createLabel(title, data) {
    return (
      <Grid container>
        <Grid item xs={4}>
          <Box m={1}>{title}</Box>
        </Grid>
        <Grid item xs={8}>
          <Box m={1}>{data}</Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Dialog
      open={modalStatus}
      // onClose={closeModal}
      maxWidth="lg"
      scroll="paper"
    >
      <Box>
        <form>
          <Box m={2}>
            <Typography variant="h6">訂購資料</Typography>
            <DialogContent dividers>
              <Grid container>
                {orderDataList.map((data, index) => {
                  return (
                    <Grid item xs={xsSize} md={mdSize} key={index}>
                      {data}
                    </Grid>
                  );
                })}
              </Grid>
              {isExist ? (
                <Grid container>
                  {importExistingOrderFields.map((data, index) => {
                    return (
                      <Grid item xs={xsSize} md={mdSize} key={index}>
                        {data}
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <></>
              )}
              {/* {isTrialPlan ? (
                <Grid container>
                  {trialOrderOperation.map((data, index) => {
                    return (
                      <Grid item xs={xsSize} md={mdSize} key={index}>
                        {data}
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (<></>)} */}
              <hr />
              <Grid container>
                {memberDataList.map((data, index) => {
                  return (
                    <Grid item xs={xsSize} md={mdSize} key={index}>
                      {data}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={2}>
                      <Box m={1}>{"備註:"}</Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Box m={1}>
                        <TextField
                          fullWidth
                          multiline
                          rows={5}
                          variant="outlined"
                          name="operatorNote"
                          onChange={handleChangeOrder}
                          value={order.operatorNote}
                          disabled={keepModal}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <SnackbarProvider maxSnack={1}>
                <DialogButtons
                  hn={member.aaHnNo}
                  order={order}
                  googlePostalAddress={googlePostalAddress}
                  closeModal={closeModal}
                  openDetailModal={openDetailModal}
                  keepModal={keepModal}
                  setKeepModalStatus={setKeepModalStatus}
                  setOrder={setOrder}
                  domainError={domainError}
                  isExist={isExist}
                />
              </SnackbarProvider>
            </DialogActions>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}

function DialogButtons(props) {
  const { enqueueSnackbar } = useSnackbar();
  // const [googlePostalAddressSn, setGooglePostalAddressSn] = useState("");

  const handleShowMessage = useCallback(
    (message, variant, isMoment = true) => {
      var autoHideDuration = 5000;
      if (!isMoment) {
        autoHideDuration = 500000;
      }
      enqueueSnackbar(message, {
        variant,
        autoHideDuration: autoHideDuration,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    },
    [enqueueSnackbar]
  );

  // const gwsCheckDomainByDomain = () => {
  //   // var message = "(MS)檢查網域";
  //   // handleShowMessage(`${message}...`, "info", false);

  //   if (props.order.domain !== "") {
  //     Axios.post("/api/v0/gws/customers/{customerId}/domains/{domain}", {
  //     // Axios.post("/api/v0/mpnCheckDomainByDomain", {
  //       domain: props.order.domain,
  //     })
  //       .then((response) => {
  //         handleShowMessage(`${message}---Success`, "success");
  //       })
  //       .catch((error) => {
  //         // var postErr = showPostError(message, error);
  //         // handleShowMessage(`${postErr}`, "error");
  //       });
  //   }
  // };

  async function handleSaveData() {
    missingK1Warning();
    console.log("------ handlerSaveData() ------");
    console.table(+props.hn);
    console.table(props.order);
    console.table(props.googlePostalAddress);

    // Order Form 防呆判斷
    let message = "";
    if (props.hn === "") {
      message = message + "【HN 帳號】\n";
    }
    if (
      props.order.productDetailSn === "" ||
      isNaN(props.order.productDetailSn)
    ) {
      message = message + "【訂閱商品】\n";
    }
    if (
      props.isExist === false &&
      (props.order.domain === "" || props.domainError)
    ) {
      message = message + "【使用網域】\n";
    }
    if (props.order.googleAlternateEmail === "") {
      message = message + "【備用信箱】\n";
    }
    if (props.googlePostalAddress.contactName === "") {
      message = message + "【聯絡人姓名】\n";
    }
    if (props.googlePostalAddress.organizationName === "") {
      message = message + "【組織或企業名稱】\n";
    }
    if (props.googlePostalAddress.addressLine1 === "") {
      message = message + "【地址 1】\n";
    }
    if (props.googlePostalAddress.postalCode === "") {
      message = message + "【郵遞區號】\n";
    }
    if (
      props.order.googleRenewalTypeSn === "" ||
      isNaN(props.order.googleRenewalTypeSn)
    ) {
      message = message + "【年度續訂設置】\n";
    }
    if (
      props.order.googleMaximumSeats === "" ||
      props.order.googleMaximumSeats === 0
    ) {
      message = message + "【最大訂閱量】\n";
    }
    if (props.isExist === true) {
      if (props.order.id === "") {
        message = message + "【Google 客戶 ID】\n";
      }
      if (props.order.googleSubscriptionId === "") {
        message = message + "【Google 訂閱 ID】\n";
      }
      if (props.order.orderStartDate === "") {
        message = message + "【訂單開通日期】\n";
      }
    }
    if (message !== "") {
      alert("以下內容未填入或有誤：\n" + message);
      return false;
    }

    // Order Form 儲存資料
    if (!window.confirm("確定儲存資料？")) {
      return;
    }
    //鎖定欄位不給更改
    props.setKeepModalStatus(true);
    //Save GooglePostalAddress obj
    await Axios.post(saveNewGooglePostalAddressURL, {
      googlePostalAddress: props.googlePostalAddress,
    })
      .then((res) => {
        const googlePostalAddressSn = res?.data?.googlePostalAddressSn;

        handleShowMessage(
          "系統 GWS Postal Address 資料已儲存...",
          "info",
          false
        );

        if (googlePostalAddressSn !== "") {
          Axios.post(saveNewOrderURL, {
            aaHnNo: props.hn,
            order: { ...props.order, PostalAddressSn: googlePostalAddressSn },
          })
            .then((res) => {
              handleShowMessage("系統訂單已儲存...", "info", false);
              // setTimeout(function () {
              //   window.location.reload();
              // }, 2000);
              props.closeModal();
            })
            .catch((err) => {
              console.log(err);
              // alert("系統訂單儲存失敗");
              handleShowMessage("系統訂單儲存失敗", "error", false);
              props.setKeepModalStatus(false);
              return;
            })
            .finally(() => {
              // 最後要更新重要資訊 googlePostalAddressSn，如果有錯誤沒重抓資料的話。
              props.setOrder((prevState) => ({
                ...prevState,
                PostalAddressSn: googlePostalAddressSn,
              }));
            });
        }
      })
      .catch((err) => {
        console.log("GooglePostalAddress_insert_fail:" + err);
        props.setKeepModalStatus(false);
        alert("後端異常");
        return;
      });
  }

  // useEffect(() => {
  //   if (
  //     googlePostalAddressSn !== "" &&
  //     props.order.PostalAddressSn !== "" &&
  //     props.order.PostalAddressSn !== undefined
  //   ) {
  //     // var postData = {};
  //     // for (var key in props.order) {
  //     //   console.log(key);
  //     //   console.log(props.order[key]);
  //     //   if (key === "googleAlternateEmail") {
  //     //     postData[key] = props.order[key] + props.order["domain"];
  //     //   } else {
  //     //     postData[key] = props.order[key];
  //     //   }
  //     // }
  //     Axios.post(saveNewOrderURL, {
  //       aaHnNo: props.hn,
  //       order: props.order,
  //     })
  //       .then((res) => {
  //         handleShowMessage("系統訂單已儲存...", "info", false);
  //         setTimeout(function () {
  //           window.location.reload();
  //         }, 2000);
  //         // props.closeModal()
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         alert("系統訂單儲存失敗");
  //         props.setKeepModalStatus(false);
  //         return;
  //       });
  //   }
  // }, [props, googlePostalAddressSn, handleShowMessage]);

  function missingK1Warning() {
    if (props.order.contractNoK1 === "") {
      alert("K1 碼尚未填入, 重要合約資料, 請確認是否需填寫!");
    }
  }

  return (
    <React.Fragment>
      {/* <Button
        onClick={mpnCheckDomainByDomain}
        color="primary"
        disabled={props.keepModal}
      >
        檢查網域
      </Button> */}
      <Button
        onClick={handleSaveData}
        color="primary"
        disabled={props.keepModal}
      >
        儲存
      </Button>
      <Button
        onClick={props.closeModal}
        color="primary"
        disabled={props.keepModal}
      >
        離開
      </Button>
    </React.Fragment>
  );
}
