import React, { useState } from "react";
// import { styled } from '@material-ui/core/styles';
import Styled from 'styled-components';
import Button from "@material-ui/core/Button";
import axios from "axios";
import Swal from "sweetalert2";

const GridContainer = Styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
`;

const MPNButton = Styled(Button)`
  && {
    font-size: 1.1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #E72F57 30%, #FF8E53 90%);
    border-radius: 6px;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    padding: 10px;
    color: white;
    width: 100%;  // 修改寬度以適應網格
    height: 100%; // 修改高度以適應網格
    transition:.3s;
  }
  &&:hover {
     background: linear-gradient(45deg, #E72F57 40%, #FF8E53 70%);
     color: black;
  }
`

export default function MPN() {
  const [msg, setMsg] = useState("");

  const mpnAuthorize = () => {
    axios
      .get("/api/v0/getAuthorizeURL")
      .then(function (response) {
        console.log(response);
        window.location.href = response.data.url;
      })
      .catch(function (error) {
        alert(error.response.data);
      });
  };

  const gwsAuthorize = () => {
    axios
      .get("/google/btn/authorize")
      .then(function (response) {
        console.log(response);
        window.location.href = response.data.url;
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const mpnRefreshToken = () => {
    axios
      .get("/api/v0/mpnRefreshToken")
      .then(function (response) {
        console.log(response);
        alert("token updated");
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const mpnGetCustomerList = () => {
    axios
      .get("/api/v0/mpnGetCustomerList")
      .then(function (response) {
        console.log(response);
        setMsg(response.data.message);
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const mpnDeleteAllCustomers = () => {
    axios
      .get("/api/v0/mpnDeleteAllCustomers")
      .then(function (response) {
        console.log(response);
        setMsg(response.data.message);
        alert("just wait until finish");
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const mpnGetAgreementsLink = () => {
    axios
      .get("/api/v0/mpnGetAgreements")
      .then(function (response) {
        console.log(response);
        setMsg(response.data.message);
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const mpnDownloadAgreements = () => {
    axios
      .get("/api/v0/mpnDownloadAgreements")
      .then(function (response) {
        console.log(response);
        setMsg(response.data.message);
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const mpnGetOfferList = () => {
    axios
      .get("/api/v0/mpnGetOfferList")
      .then(function (response) {
        console.log(response);
        setMsg(response.data.message);
      })
      .catch(function (error) {
        alert(error);
      });
  };

  let isDownloading = false;

  const downloadEventLogFiles = () => {
    if (isDownloading) {
      alert("正在下載中，請稍候...");
      return;
    }

    isDownloading = true;

    axios({
      url: "/api/log/retrieveLogFiles",
      method: 'GET',
      responseType: 'blob',
    })
      .then(response => {
        const href = window.URL.createObjectURL(response.data);
        console.log(response.data);

        const anchorElement = document.createElement('a');
        anchorElement.href = href;
        const timeNowString = new Date().toISOString().split("T")[0];
        anchorElement.download = "log_" + timeNowString + ".tar.bz2";

        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch(error => {
        console.log('error: ', error);
        alert("下載失敗!");
      })
      .finally(() => {
        isDownloading = false;
      });
  };

  let isDownloadingDsis = false;

  const downloadDsisFiles = () => {
    if (isDownloadingDsis) {
      alert("正在下載中，請稍候...");
      return;
    }

    isDownloadingDsis = true;

    axios({
      url: "/api/log/retrieveDsisFiles",
      method: 'GET',
      responseType: 'blob',
    })
      .then(response => {
        const href = window.URL.createObjectURL(response.data);
        console.log(response.data);

        const anchorElement = document.createElement('a');
        anchorElement.href = href;
        const timeNowString = new Date().toISOString().split("T")[0];
        anchorElement.download = "dsis_" + timeNowString + ".zip";

        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch(error => {
        console.log('error: ', error);
        alert("下載失敗!");
      })
      .finally(() => {
        isDownloadingDsis = false;
      });
  };

  let isDownloading_New = false;

  const downloadEventLogFiles_New = () => {
    if (isDownloading_New) {
      alert("正在下載中，請稍候...");
      return;
    }

    isDownloading_New = true;

    axios({
      url: "/api/log/retrieveLogFiles_New",
      method: 'GET',
      responseType: 'blob',
    })
      .then(response => {
        const href = window.URL.createObjectURL(response.data);
        console.log(response.data);

        const anchorElement = document.createElement('a');
        anchorElement.href = href;
        const timeNowString = new Date().toISOString().split("T")[0];
        anchorElement.download = "log_" + timeNowString + ".zip";

        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch(error => {
        console.log('error: ', error);
        alert("下載失敗!");
      })
      .finally(() => {
        isDownloading_New = false;
      });
  };

  let isDownloadingDsis_New = false;

  const downloadDsisFiles_New = () => {
    if (isDownloadingDsis_New) {
      alert("正在下載中，請稍候...");
      return;
    }

    isDownloadingDsis_New = true;

    axios({
      url: "/api/log/retrieveDsisFiles_New",
      method: 'GET',
      responseType: 'blob',
    })
      .then(response => {
        const href = window.URL.createObjectURL(response.data);
        console.log(response.data);

        const anchorElement = document.createElement('a');
        anchorElement.href = href;
        const timeNowString = new Date().toISOString().split("T")[0];
        anchorElement.download = "dsis_" + timeNowString + ".zip";

        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch(error => {
        console.log('error: ', error);
        alert("下載失敗!");
      })
      .finally(() => {
        isDownloadingDsis_New = false;
      });
  };

  const handleUnusualOrderInformationManually = () => {
    Swal.fire({
      title: "請輸入需要刪除的出帳紀錄 OTPW ",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "確定",
      showLoaderOnConfirm: true,
      preConfirm: (AaOtpw) => {
        axios.post("/api/order/handleUnusualOrderInformationManually", { AaOtpw: AaOtpw })
          .then(response => {
            if (response.data) {
              console.log(response.data);
              return Swal.showValidationMessage(`
              請前往首頁確認訂單（OrderNo：${response.data.message}）的出帳紀錄。
            `);
            }
          })
          .catch(error => {
            console.log('error: ', error);
            console.log(error.response);
            Swal.fire({
              title: `Error: ${error.response.data.message}`,
              icon: 'error',
            });
            Swal.showValidationMessage(`
            Request failed: ${error}
          `);
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `已成功刪除。`,
          icon: 'success',
        });
      }
    });
  }

  const handleGetPairOfMacAndTimestamp = () => {
    axios
      .post("/OP/user/getPairOfMacAndTimestamp")
      .then(function (response) {

        Swal.fire({
          position: 'center',
          title: '驗證參數',
          html: "<p>X-Mac：<b>" + response.data['X-Mac'] + "</b></p><p>X-Timestamp：<b>" + response.data['X-Timestamp'] + "</b></p>",
          icon: 'success',
          scrollbarPadding: true,
          showConfirmButton: true,
          confirmButtonColor: '#28a8ea'
        });
      })
      .catch(function (error) {
        alert(error.response.data);
      });
  }

  const navigateToHeadJs = () => {
    window.location.href = "/backoffice/HEAD"; // 修改為HEAD.js的實際路徑
  }

  return (
    <>
    <GridContainer>
    <MPNButton variant="contained" onClick={mpnAuthorize}>
        MPN Authorize
      </MPNButton>

      <MPNButton variant="contained" onClick={gwsAuthorize}>
        GWS Authorize
      </MPNButton>

      <MPNButton variant="contained" onClick={downloadEventLogFiles}>
        Event Log Download
      </MPNButton>

      <MPNButton variant="contained" onClick={downloadDsisFiles}>
        Dsis Files Download
      </MPNButton>

      <MPNButton variant="contained" onClick={mpnRefreshToken}>
        MPN Refresh Token
      </MPNButton>

      {process.env.REACT_APP_MODE === "development" ? (
        <React.Fragment>
          <MPNButton variant="contained" onClick={mpnGetCustomerList}>
            Get Customer List
          </MPNButton>
          <MPNButton variant="contained" onClick={mpnDeleteAllCustomers}>
            Delete All Customers
          </MPNButton>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}

      <MPNButton variant="contained" onClick={mpnGetAgreementsLink}>
        Get Agreements ID
      </MPNButton>

      <MPNButton variant="contained" onClick={mpnDownloadAgreements}>
        Download Agreements
      </MPNButton>

      <MPNButton variant="contained" onClick={mpnGetOfferList}>
        get offer list
      </MPNButton>

      <MPNButton variant="contained" onClick={handleUnusualOrderInformationManually}>
        手動處理異常出帳紀錄
      </MPNButton>

      <MPNButton variant="contained" onClick={handleGetPairOfMacAndTimestamp}>
        取得一組驗證參數
      </MPNButton>

      <MPNButton variant="contained" onClick={navigateToHeadJs}>
          前往SQL作業
      </MPNButton>

      <MPNButton variant="contained" onClick={downloadEventLogFiles_New}>
        Event Log Download USE ZIP
      </MPNButton>

      <MPNButton variant="contained" onClick={downloadDsisFiles_New}>
        Dsis Files Download USE ZIP
      </MPNButton>
    </GridContainer>      

      <br />

      {msg}
    </>
  );
}
