import axios from "axios";

let [op_session, op_name, op_group_id] = ["session", "op_name", "group_id"];

export const duration = 15 * 60;
export const ignoredPaths = "/backoffice/Login";

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const setCookie = (cookie_name, cookie_value) => {
    switch (process.env.REACT_APP_MODE) {
        case "development":
            document.cookie = `${cookie_name}=${cookie_value}; Max-Age=${duration}; Path=/`;
            break;
        default:
            document.cookie = `${cookie_name}=${cookie_value}; Max-Age=${duration}; SameSite=Lax; Secure; Path=/`;
    }

    // console.log(`Set cookie_name:${cookie_name} / cookie_value:${cookie_value} / cookie_value_type:${typeof cookie_value}`)
}

function deleteCookie(name, path) {
    document.cookie = name +'=; Path='+path+'; Max-Age=-99999999;';
}

const refreshCookie = (cookie_name) => {
    let cookie_value = getCookie(cookie_name)
    setCookie(cookie_name, cookie_value);
}

export const handleInitLoginCookies = async (op_session_value, op_name_value, op_group_id_value) => {
    if(op_session_value === undefined || op_name_value === undefined || op_group_id_value === undefined) {
        console.log("因cookie值未定義，故不設定登入session。")
    } else {
        setCookie(op_session, op_session_value)
        setCookie(op_name, op_name_value)
        setCookie(op_group_id, op_group_id_value)

        // 設定 axios 全域變數
        axios.defaults.headers.common["X-RenZheng"] = op_session_value;

        console.log("已設定登入session。")
    }
    return Promise.resolve()
}

export const handleCheckLoginCookies = () => {
    if(getCookie(op_session) === undefined)
        return false
    if(getCookie(op_name) === undefined)
        return false
    if(getCookie(op_group_id) === undefined)
        return false
    return true
}

export const handleRefreshLoginCookies = () => {
    refreshCookie(op_session)
    refreshCookie(op_name)
    refreshCookie(op_group_id)
    console.log("已刷新登入 session。")
}

export const handleExpiredCookies = () => {
    handleClearLoginCookies()
    console.log("登入 session 已過期。")
    alert("系統已登出，請重新登入。")
}

export const handleClearLoginCookies = () => {
    deleteCookie(op_session, '/')
    deleteCookie(op_name, '/')
    deleteCookie(op_group_id, '/')
    deleteCookie(op_session, '/backoffice')
    deleteCookie(op_name, '/backoffice')
    deleteCookie(op_group_id, '/backoffice')
}

// export const handleGoBackLoginPage = () => {
//     switch (process.env.REACT_APP_MODE) {
//         case "development":
//             document.location.href = ignoredPaths;
//             break;
//         case "production":
//             document.location.href = process.env.REACT_APP_API_ADDRESS + ignoredPaths.slice(1);
//             break;
//         default:
//             document.location.href = process.env.REACT_APP_API_ADDRESS + ignoredPaths;
//     }
// }

// const handleRefreshLogin = (session_value, op_name_value, group_id_value) => {
//   // check logging status
//   let isLogin = false
//   if(cookies.session !== undefined && 
//     cookies.op_name === undefined && 
//     cookies.group_id === undefined) 
//     isLogin = true
//   else isLogin = false
  
//   // refresh session
//   if(isLogin){
//     setCookie("session", session_value, { secure: true, maxAge: 15 * 60 })
//     setCookie("op_name", op_name_value, { secure: true, maxAge: 15 * 60 })
//     setCookie("group_id", group_id_value, { secure: true, maxAge: 15 * 60 })
//     console.log("已刷新登入session。")
//   } else {
//     removeCookie("session")
//     removeCookie("op_name")
//     removeCookie("group_id")
//     console.log("登入session已過期。")
//     history.replace("/backoffice")
//   }
// }